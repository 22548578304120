<template>
  <div>
    <div class="role-btn">
      <b-button
        variant="outline-primary"
        size="sm"
        v-if="isUserList"
        class="v2-back"
        @click="consumerShow"
      >
        <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
      </b-button>
    </div>
    <b-tabs pills class="con">
      <!-- Tab: Account -->
      <template #empty v-if="!isUserList">
        <b-card class="mt-306 added-data">
          <ConsumerList :userListShow="userListShow" />
        </b-card>
      </template>
      <!-- </b-tab> -->

      <b-tab
        @click="activeTab('users', false)"
        :active="selected_tab === 'users'"
        v-if="
          isUserList &&
          checkAbility({
            action: constants.PERMISSIONS_ACTION.READ,
            subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT_CONSUMER
          })
        "
      >
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("user.User") }}</span>
        </template>
        <b-card class="mt-36">
          <div class="adduser-btn-row">
            <h4 class="card-title">
              {{ this.selectedAccount && this.selectedAccount.name }}
            </h4>
          </div>
          <UserListComp :selectedAccount="selectedAccount" />
        </b-card>
      </b-tab>

      <!-- Tab: Information -->

      <b-tab
        :active="selected_tab === 'invitations'"
        @click="activeTab('invitations', false)"
        v-if="
          isUserList &&
          checkAbility({
            action: constants.PERMISSIONS_ACTION.READ,
            subject: constants.PERMISSIONS_MODEL.VW_CHILDREN_ACCOUNTS
          })
        "
      >
        <template #title>
          <feather-icon icon="UserPlusIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("user.invitations") }}</span>
        </template>
        <b-card class="mt-36">
          <div
            class="adduser-btn-row"
            v-if="
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.VW_CHILDREN_ACCOUNTS
              })
            "
          >
            <h4 class="card-title">
              {{ $t("user.ListInvitations") }}
            </h4>
            <b-button
              variant="primary"
              v-b-tooltip.hover.v-primary
              :title="$t('tooTip.AddInvite')"
              class="btn-icon"
              @click="ShowSentInvitation"
            >
              <feather-icon icon="UserPlusIcon" />
               <span class="d-none d-sm-inline ml-1">{{ $t("tooTip.AddInvite") }}</span>
            </b-button>
          </div>

          <InvitationsList :selectedAccount="selectedAccount" />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BTooltip,
  VBTooltip
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import ConsumerList from "@/layouts/components/ConsumerOnboarding/ConsumerList.vue";
import ConsumerInvitationsList from "@/layouts/components/ConsumerOnboarding/ConsumerInvitationsList.vue";
import InvitationsList from "@/layouts/components/UserOnboarding/InvitationsList.vue";
import UserListComp from "@/layouts/components/UserOnboarding/UserListComp.vue";
import constants from "@/utils/constants";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    ConsumerList,
    ConsumerInvitationsList,
    InvitationsList,
    UserListComp,
    BButton,
    BTooltip,
    VBTooltip
  },
  data() {
    return {
      isUserList: false,
      selectedAccount: {},
      constants,
      selected_tab: "users"
    };
  },
  watch: {
    $route(to, from) {
      const query = this.$route.query && this.$route.query.tab;
      if (query === "users") {
        this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.filter(
          (e) => e.text !== "Breadcrumb_Invitations"
        );
        this.selected_tab = this.$route.query.tab;
      } else if (query === "invitations") {
        this.$route.meta.breadcrumb.forEach((e) => {
          if (e.text === "Breadcrumb_Users") {
            e.active = false;
            e.to = {
              name: "consumer",
              query: { tab: "users" }
            };
          }
        });
        this.selected_tab = this.$route.query.tab;
      }
    }
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  mounted() {
    if (this.$route.meta.breadcrumb && this.$route.meta.breadcrumb.length) {
      this.$route.meta.breadcrumb = [
        ...this.constants.CONSUMER_ROUTE_META_BREADCRUMB
      ];
    }
    if (this.$route.query.tab) {
      this.selected_tab = this.$route.query.tab;
      // this.$router.replace({ query: null });
    }
  },
  methods: {
    userListShow(e) {
      this.isUserList = true;
      this.selectedAccount = e;
    },
    ShowSentInvitation() {
      this.$bvModal.show("modal-sm-invitations");
    },
    consumerShow() {
      this.isUserList = false;
      this.$router.replace({ query: {} });
      if (
        this.selectedAccount &&
        this.selectedAccount.name &&
        this.$route.meta.breadcrumb &&
        this.$route.meta.breadcrumb.length
      ) {
        this.$route.meta.breadcrumb = [
          ...this.constants.CONSUMER_ROUTE_META_BREADCRUMB
        ];
      }
      this.$route.meta.breadcrumb =
        this.constants.CONSUMER_ROUTE_META_BREADCRUMB;
    },
    activeTab(tab, isRedirect) {
      this.selected_tab = tab;

      this.$router.replace({ query: { tab } }).catch((e) => {});

      if (this.$route.query && this.$route.query.tab === "invitations") {
        this.$route.meta.breadcrumb.forEach((e) => {
          if (e.text === "Breadcrumb_Users") {
            e.active = false;
            e.to = {
              name: "consumer",
              query: { tab: "users" }
            };
          }
        });
        const isExists = this.$route.meta.breadcrumb.find(
          (e) => e.text === "Breadcrumb_Invitations"
        );
        if (!isExists || !isExists.text) {
          this.$route.meta.breadcrumb.push({
            text: "Breadcrumb_Invitations",
            active: true
          });
        }
      } else if (this.$route.query && this.$route.query.tab === "users") {
        if (this.$route.query && this.$route.query.tab === "users") {
          this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.filter(
            (e) => e.text !== "Breadcrumb_Invitations"
          );
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
