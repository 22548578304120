<template>
  <b-modal
    id="modal-sm-consumer-invitations"
    centered
    modal-class="no-header-modal"
  >
    <b-card-text>
      <div class="new-invite-modal-title">{{ $t("user.SendInvite") }}</div>
      <div class="you-invite">{{ $t("user.Invitenewmembers") }}</div>
      <b-form class="mtt-37">
        <b-form-group :label="$t('user.Email')">
          <b-form-input id="name" placeholder="example@gmail.com" />
        </b-form-group>
        <b-form-group :label="$t('user.Role') + '*'" label-for="vue-select">
          <v-select id="vue-select" v-model="selected" :options="option" />
        </b-form-group>
        <div class="d-flex justify-content-center mb-64 mtt-50">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            {{ $t("user.Accept") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
          >
            {{ $t("user.Decline") }}
          </b-button>
        </div>
      </b-form>
    </b-card-text>
  </b-modal>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BModal,
  BForm
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormGroup,
    BModal,
    BForm
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
}
</style>
